import React from 'react'
import ihs from '../images/ihs.png'
import { IconBackground } from '../components/IconBackground'
import Layout from '../components/mdxLayout'
import { ArrowRightIcon } from '@heroicons/react/outline'

export default () => {
  return (
    <Layout>
      <>
        <div className="bg-grape">
          <div className="relative overflow-hidden">
            <div className="text-white opacity-10">
              <IconBackground />
            </div>

            <div className="relative pb-16 sm:pb-18">
              <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
                <div className="text-center">
                  <div className="px-4 sm:px-6 mb-12 sm:mb-16">
                    <img
                      data-sal="slide-up" data-sal-delay="1200" data-sal-duration="1000" data-sal-easing="ease"
                      className="mx-auto max-h-[140px] sm:max-h-[180px]"
                      src={ihs}
                      alt="Independent Hotel Show"
                    />
                  </div>

                  <h1 className="my-6 text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                    <span data-sal="fade" data-sal-delay="" data-sal-duration="1000" data-sal-easing="ease" className="block">Win a <span className="text-violet">year's free</span> subscription!</span>
                  </h1>

                  <div data-sal="fade" data-sal-delay="600" data-sal-duration="1000" data-sal-easing="ease">
                    <p className="mt-3 max-w-md mx-auto text-base text-white opacity-70 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                      Enter your details for your chance to win
                    </p>
                  </div>

                  <div data-sal="fade" data-sal-delay="900" data-sal-duration="800" data-sal-easing="ease">
                    <div className="flex items-center justify-center space-x-6 my-8">
                      <a href="https://forms.gle/jr79KxXrr94MKDvF8" target="_blank" title="Enter to win" className="border border-gray-300 shadow-sm bg-white text-grape rounded-lg font-medium text-lg p-4 px-6 hover:gray-50">
                        Enter draw 🤞
                      </a>
                    </div>
                    <a
                      href="https://calendly.com/amy-trybe/15min"
                      title="Schedule a demo"
                      className="text-white font-medium"
                    >
                      or{' '}<span className="text-violet">schedule a demo{' '}<ArrowRightIcon className="w-6 h-6 inline" /></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative">
              <div className="absolute inset-0 flex flex-col" aria-hidden="true">
                <div className="flex-1" />
                <div className="flex-1 w-full bg-violet-light relative text-opacity-25 text-violet">
                  <IconBackground />
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}
